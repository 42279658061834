import { Injectable } from '@angular/core';
import { concatMap, filter, Observable, tap } from 'rxjs';
import { ActivityGenericDataService } from 'src/app/asset-module/services/activity-generic-data.service';
import { InspectionDataService } from 'src/app/asset-module/services/inspection-data.service';
import { ActivityDetailDto } from 'src/app/models/activity-detail-dto';
import { ActivityTemplates } from 'src/app/models/enums/activity-templates';
import { DialogService } from 'src/app/shared-module/dialog.service';
import { DialogButtons } from 'src/app/shared-module/models/dialog-buttons';
import { DialogData } from 'src/app/shared-module/models/dialog-data';
import { DialogResult } from 'src/app/shared-module/models/dialog-result';
import { NotificationService } from 'src/app/shared-module/services/notification.service';
import { SurveyService } from 'src/app/survey.service';

@Injectable({
  providedIn: 'root'
})
export class ActivityHistoryDeleteService {
  public constructor(
    private genericService: ActivityGenericDataService,
    private inspectionService: InspectionDataService,
    private surveyService: SurveyService,
    private dialogService: DialogService,
    private notificationService: NotificationService
  ) {}

  public deleteActivity(activity: ActivityDetailDto): Observable<boolean> {
    const deleteDialogData =
      activity.template === ActivityTemplates.Survey
        ? this.getDeleteSurveyDialog()
        : this.getDeleteGenActDialog(activity.name!);

    return this.dialogService.display(deleteDialogData).pipe(
      filter((x) => x === DialogResult.yes),
      concatMap(() => {
        switch (activity.template!) {
          case ActivityTemplates.Generic:
            return this.genericService.deleteGenericActivity(activity.id!);
          case ActivityTemplates.Survey:
            return this.surveyService.deleteSurvey(activity.id!);
          case ActivityTemplates.Inspection:
            return this.inspectionService.deleteInspection(activity.id!);
        }
      }),
      tap((success) => {
        this.notificationService.showSaveResult(success, 'Delete');
      })
    );
  }

  private getDeleteSurveyDialog(): DialogData {
    const title = 'Delete All Readings?';
    const message = 'All related readings will be deleted';
    const yesButtonText = 'Delete Survey';

    return this.getDeleteDialog(title, message, yesButtonText);
  }

  private getDeleteGenActDialog(name: string): DialogData {
    const title = `Delete "${name}"`;
    const message = 'Are you sure?';
    const yesButtonText = 'Delete';

    return this.getDeleteDialog(title, message, yesButtonText);
  }

  private getDeleteDialog(title: string, message: string, yesButtonText: string): DialogData {
    return {
      title: title,
      message: message,
      buttons: DialogButtons.YesCancel,
      yesButtonText: yesButtonText
    };
  }
}
