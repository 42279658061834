import { E2gSelectOption } from '@equityeng/e2g-ng-ui';

export enum AssetTypes {
  AirCooler = 1,
  Boiler = 2,
  Compressor = 3,
  FiredHeater = 4,
  HorizontalVessel = 5,
  PipingSystem = 6,
  PressureReliefDevice = 7,
  Pump = 8,
  ShellTubeHeatExchanger = 9,
  Sphere = 10,
  StorageTank = 11,
  VerticalVessel = 12
}

export function getScraAssetType(type: AssetTypes): string {
  switch (type) {
    case AssetTypes.AirCooler:
      return 'Air Cooler';
    case AssetTypes.Boiler:
      return 'Boiler';
    case AssetTypes.Compressor:
      return 'Compressor';
    case AssetTypes.FiredHeater:
      return 'Fired Heater';
    case AssetTypes.HorizontalVessel:
      return 'Horizontal Vessel';
    case AssetTypes.PipingSystem:
      return 'Piping System';
    case AssetTypes.PressureReliefDevice:
      return 'Pressure Relief Device';
    case AssetTypes.Pump:
      return 'Pump';
    case AssetTypes.ShellTubeHeatExchanger:
      return 'Shell Tube Heat Exchanger';
    case AssetTypes.Sphere:
      return 'Sphere';
    case AssetTypes.StorageTank:
      return 'Storage Tank';
    case AssetTypes.VerticalVessel:
      return 'Vertical Vessel';
    default:
      throw `Unknown Asset Type: ${type}`;
  }
}

export function getScraAssetTypes(): Array<E2gSelectOption> {
  return [
    { label: getScraAssetType(AssetTypes.AirCooler), value: AssetTypes.AirCooler },
    { label: getScraAssetType(AssetTypes.Boiler), value: AssetTypes.Boiler },
    { label: getScraAssetType(AssetTypes.Compressor), value: AssetTypes.Compressor },
    { label: getScraAssetType(AssetTypes.FiredHeater), value: AssetTypes.FiredHeater },
    { label: getScraAssetType(AssetTypes.HorizontalVessel), value: AssetTypes.HorizontalVessel },
    { label: getScraAssetType(AssetTypes.PipingSystem), value: AssetTypes.PipingSystem },
    { label: getScraAssetType(AssetTypes.PressureReliefDevice), value: AssetTypes.PressureReliefDevice },
    { label: getScraAssetType(AssetTypes.Pump), value: AssetTypes.Pump },
    { label: getScraAssetType(AssetTypes.ShellTubeHeatExchanger), value: AssetTypes.ShellTubeHeatExchanger },
    { label: getScraAssetType(AssetTypes.Sphere), value: AssetTypes.Sphere },
    { label: getScraAssetType(AssetTypes.StorageTank), value: AssetTypes.StorageTank },
    { label: getScraAssetType(AssetTypes.VerticalVessel), value: AssetTypes.VerticalVessel }
  ];
}

export function getSageAssetType(type: AssetTypes): string {
  switch (type) {
    case AssetTypes.AirCooler:
      return 'Pressure Vessels';
    case AssetTypes.Boiler:
      return 'Pressure Vessels';
    case AssetTypes.Compressor:
      return 'ALL';
    case AssetTypes.FiredHeater:
      return 'Fired Heaters';
    case AssetTypes.HorizontalVessel:
      return 'Pressure Vessels';
    case AssetTypes.PipingSystem:
      return 'Piping and Boiler Tubes';
    case AssetTypes.PressureReliefDevice:
      return 'Pressure Relief Devices';
    case AssetTypes.Pump:
      return 'ALL';
    case AssetTypes.ShellTubeHeatExchanger:
      return 'Heat Exchangers';
    case AssetTypes.Sphere:
      return 'Pressure Vessels';
    case AssetTypes.StorageTank:
      return 'Tanks';
    case AssetTypes.VerticalVessel:
      return 'Pressure Vessels';
    default:
      throw `Unknown Asset Type: ${type}`;
  }
}
