import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { AuthError, AuthModule, AuthService, GenericError } from '@equityeng/auth';
import { E2gColorAdapter, E2gNgUiModule } from '@equityeng/e2g-ng-ui';
import { GraphicsConfigService, SOLIDMODEL_STATUS_SERVICE } from '@equityeng/graphics';
import { SAGE_ENV_SETTINGS, SageEnvironmentService } from '@equityeng/sage-forms-core';
import { SageFormService } from '@equityeng/sage-forms-core/sage-form';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { merge } from 'rxjs';
import { ReportService } from 'src/app/report.service';

import { AlertService } from './alert.service';
import { AppRoutingModule } from './app-routing.module';
import { AppSettingsHttpService } from './app-settings/app-settings-http.service';
import { AppComponent } from './app.component';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { AuthErrorComponent } from './auth-error/auth-error.component';
import { HomeComponent } from './home/home/home.component';
import { MenuComponent } from './home/menu/menu.component';
import { ShellComponent } from './home/shell/shell.component';
import { LandingTargetComponent } from './landing-target.component';
import { NotificationsModule } from './notifications-module/notifications.module';
import { OnDestroyBaseComponent } from './on-destroy-base-component/on-destroy-base-component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ReportingModule } from './reporting-module/reporting.module';
import { RouteInspectionService } from './route-inspection.service';
import { SageBridgeService } from './sage-bridge.service';
import { SharedModule } from './shared-module/shared.module';
import { SlideOutModule } from './slide-out-module/slide-out.module';
import { UserService } from './user.service';

export function appInit(settingsHttpService: AppSettingsHttpService): any {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return () => settingsHttpService.initializeApp();
}

export class CustomE2gColorAdapter extends E2gColorAdapter {
  public getRequiredColor(): string {
    return '#FFFFC0';
  }

  public getRequiredColorClass(): string {
    return 'e2g-required';
  }
}

@NgModule({
  declarations: [
    AppComponent,
    OnDestroyBaseComponent,
    AuthCallbackComponent,
    AuthErrorComponent,
    PageNotFoundComponent,
    HomeComponent,
    ShellComponent,
    MenuComponent,
    LandingTargetComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    MatSidenavModule,
    SlideOutModule,
    BrowserAnimationsModule,
    NgbModule,
    AuthModule.forRoot(),
    SharedModule,
    ReactiveFormsModule,
    NotificationsModule,
    ReportingModule,
    E2gNgUiModule,
    FormsModule
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: appInit, deps: [AppSettingsHttpService], multi: true },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 2500, horizontalPosition: 'center', verticalPosition: 'top' }
    },
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { color: 'primary' }
    },
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: { dateFormat: 'dd-MMM-yyyy' } //Format should match what NativeDateAdapter override is using
    },
    {
      provide: E2gColorAdapter,
      useClass: CustomE2gColorAdapter
    },
    { provide: SAGE_ENV_SETTINGS, useExisting: SageEnvironmentService },
    { provide: SOLIDMODEL_STATUS_SERVICE, useExisting: GraphicsConfigService },
    SageBridgeService,
    { provide: SageFormService, useExisting: SageBridgeService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  public constructor(
    private router: Router,
    private authService: AuthService,
    private routeInspectionService: RouteInspectionService,
    private alertService: AlertService,
    private reportService: ReportService,
    private userService: UserService
  ) {
    this.authService.genericError$.subscribe((error: GenericError) => {
      console.error('Generic error: ', error);
      this.router.navigate(['auth-error']);
    });

    merge(this.authService.authenticationError$, this.authService.authorizationError$).subscribe(
      (authenticationError: AuthError) => {
        if (authenticationError.hasSessionEnded) {
          this.authService.signOut();
        }
      }
    );

    this.routeInspectionService.init();
    this.alertService.init();
    this.reportService.init();
    this.userService.init();
  }
}
