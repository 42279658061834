import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { faMemo } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown, faPencilAlt, faTrashXmark } from '@fortawesome/pro-solid-svg-icons';
import { ICellRendererParams } from 'ag-grid-community';
import { combineLatest, forkJoin, Observable, Subject } from 'rxjs';
import { concatMap, distinctUntilChanged, filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import {
  ActivityDetailsSlideoutComponent,
} from 'src/app/activity-details-module/activity-details-slideout/activity-details-slideout.component';
import {
  FutureActivityDetailsComponent,
} from 'src/app/activity-details-module/future-activity-details/future-activity-details.component';
import {
  ActivitySlideoutInput,
  HistoricalActivitySlideoutIdentifier,
} from 'src/app/activity-details-module/models/activity-slideout-input';
import { ActivitySlideoutOption } from 'src/app/activity-details-module/models/activity-slideout-option';
import { FutureActivitySlideoutInput } from 'src/app/activity-details-module/models/future-activity-slideout-input';
import { AlertService } from 'src/app/alert.service';
import { Breadcrumb } from 'src/app/breadcrumb-module/breadcrumbs/breadcrumbs.component';
import { GridCellLinkParams } from 'src/app/grid-module/cell-renders/grid-cell-link/grid-cell-link-params';
import { GridCellLinkComponent } from 'src/app/grid-module/cell-renders/grid-cell-link/grid-cell-link.component';
import { GridCellTooltipComponent } from 'src/app/grid-module/cell-renders/grid-cell-tooltip/grid-cell-tooltip.component';
import { buildActionsColDef } from 'src/app/grid-module/column-builders/build-actions';
import { getActivityHistoryGridColumns } from 'src/app/grid-module/column-definitions/activity-history-grid-columns';
import { getActivityUpcomingGridColumns } from 'src/app/grid-module/column-definitions/activity-upcoming-grid-columns';
import { E2gAgGridComponent, GridBaseOptions } from 'src/app/grid-module/e2g-ag-grid/e2g-ag-grid.component';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';
import { NEW_ITEM_KEY } from 'src/app/shared-module/models/new-item-key';
import { AssetFeature, AssetPermissionModel, NO_PERMISSION } from 'src/app/shared-module/permission/permission-models';
import { PermissionService } from 'src/app/shared-module/permission/permission.service';
import { NotificationService } from 'src/app/shared-module/services/notification.service';
import { SlideOutService } from 'src/app/slide-out-module/slide-out.service';
import { StateService } from 'src/app/state.service';
import { anyIdsInCommon } from 'src/app/utilities/array-helper';

import { ActivityTypeDataService } from '../../activity-type-data.service';
import { E2gMenuItem } from '../../grid-module/cell-renders/grid-cell-menu/e2g-menu-item';
import { GridCellMenuComponent } from '../../grid-module/cell-renders/grid-cell-menu/grid-cell-menu-component';
import { GridCellMenuParams } from '../../grid-module/cell-renders/grid-cell-menu/grid-cell-menu-params';
import { ActivityDetailDto } from '../../models/activity-detail-dto';
import { ActivityTypeDto } from '../../models/activity-type-dto';
import { ActivityTemplates } from '../../models/enums/activity-templates';
import { EquipmentUnitInput } from '../../models/equipment-unit-input';
import { RefreshService } from '../../notifications-module/refresh.service';
import { DialogService } from '../../shared-module/dialog.service';
import { DialogButtons } from '../../shared-module/models/dialog-buttons';
import { DialogData } from '../../shared-module/models/dialog-data';
import { DialogResult } from '../../shared-module/models/dialog-result';
import { FutureActivityDetailDto } from '../models/future-activity-detail-dto';
import { InspectionDto } from '../models/inspection-dto';
import { ActivityDataService } from '../services/activity-data.service';
import { FutureActivityDataService } from '../services/activity-future-data.service';
import { ActivityHistoryDeleteService } from './delete-activity-history.service';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.css']
})
export class ActivitiesComponent extends OnDestroyBaseComponent implements OnInit {
  @Input() public breadcrumbs!: Breadcrumb[];
  @Output() public updateTabId = new EventEmitter<number>();
  @ViewChild('futureActivitiesGrid', { static: false }) public futureActivitiesGrid?: E2gAgGridComponent;
  @ViewChild('activitiesHistoryGrid', { static: false }) public activitiesHistoryGrid?: E2gAgGridComponent;

  public inputData!: EquipmentUnitInput;

  public noSurveys: boolean = false;

  public inspection?: InspectionDto;
  public inspectionId?: string;

  public refreshData = new Subject<void>();

  public faTrashXmark = faTrashXmark;
  private faPencilAlt = faPencilAlt;
  public faChevronDown = faChevronDown;
  private faMemo = faMemo;

  public activeTabId: number = 1;

  public addButtonOptions: Array<string> = [];
  public activityTypes?: Array<ActivityTypeDto>;

  private pastMenuDefinition: Array<E2gMenuItem> = [
    {
      text: 'View',
      command: (params: ICellRendererParams): void => this.editHistoricalActivity(params),
      iconDefinition: { icon: this.faPencilAlt },
      visible: (): boolean => this.activityPermissions.edit
    },
    {
      text: 'Delete',
      command: (params: ICellRendererParams): void => this.delete(params),
      iconDefinition: { icon: this.faTrashXmark, color: 'var(--system-fg-error)' },
      visible: (): boolean => this.activityPermissions.delete
    },
    {
      text: 'Show Detail',
      command: (params: ICellRendererParams): void => this.editHistoricalActivity(params),
      iconDefinition: { icon: this.faMemo },
      visible: (): boolean => !this.activityPermissions.edit
    }
  ];

  private futureMenuDefinition: Array<E2gMenuItem> = [
    {
      text: 'View',
      command: (params: ICellRendererParams): void => this.onViewFutureActivitySelect(params),
      iconDefinition: { icon: this.faPencilAlt },
      visible: (): boolean => this.activityPermissions.edit
    },
    {
      text: 'Delete',
      command: (params: ICellRendererParams): void => this.deleteFutureActivity(params),
      iconDefinition: { icon: this.faTrashXmark, color: 'var(--system-fg-error)' },
      visible: (): boolean => this.activityPermissions.delete
    },
    {
      text: 'Show Detail',
      command: (params: ICellRendererParams): void => this.onViewFutureActivitySelect(params),
      iconDefinition: { icon: this.faMemo },
      visible: (): boolean => !this.activityPermissions.edit
    }
  ];

  private isChangeableActivity(params: ICellRendererParams): boolean {
    return params.data.date;
  }

  public activitiesHistoryGridOptions: GridBaseOptions = {
    exportFileName: 'Activities History',
    context: 'activities-history',
    rowSelection: 'single',
    onSelectionChanged: this.onSelectionChanged.bind(this),
    defaultColDef: {
      resizable: true,
      sortable: true,
      width: 150,
      tooltipComponent: GridCellTooltipComponent,
      filter: 'agSetColumnFilter'
    },
    columnDefs: [
      {
        headerName: 'Name',
        headerTooltip: 'Name',
        field: 'name',
        tooltipField: 'name',
        cellRenderer: GridCellLinkComponent,
        cellRendererParams: {
          isLink: (): boolean => true,
          linkCommand: (params: ICellRendererParams): void => this.editHistoricalActivity(params)
        } as GridCellLinkParams
      },
      buildActionsColDef(this.pastMenuDefinition),
      ...getActivityHistoryGridColumns()
    ]
  };

  public futureActivitiesGridOptions: GridBaseOptions = {
    exportFileName: 'Activities',
    context: 'activities',
    rowSelection: 'single',
    onSelectionChanged: this.onSelectionChanged.bind(this),
    defaultColDef: {
      resizable: true,
      sortable: true,
      width: 300,
      tooltipComponent: GridCellTooltipComponent,
      filter: 'agSetColumnFilter'
    },
    columnDefs: [
      {
        headerName: 'Type',
        headerTooltip: 'Type',
        field: 'activityType',
        tooltipField: 'activityType',
        cellRenderer: GridCellLinkComponent,
        cellRendererParams: {
          isLink: (): boolean => true,
          linkCommand: (params: ICellRendererParams): void => this.onViewFutureActivitySelect(params)
        } as GridCellLinkParams
      },
      {
        headerName: 'Actions',
        headerTooltip: 'Actions',
        width: 112,
        cellStyle: { textAlign: 'center' },
        headerClass: 'ag-header-center',
        cellRenderer: GridCellMenuComponent,
        cellRendererParams: {
          menuItems: this.futureMenuDefinition,
          isLink: (): boolean => this.activityPermissions.edit,
          linkCommand: (params: ICellRendererParams) => this.onViewFutureActivitySelect(params)
        } as GridCellMenuParams,
        filter: false
      },
      ...getActivityUpcomingGridColumns()
    ]
  };

  public pastActivities?: Array<ActivityDetailDto>;
  public futureActivities?: Array<FutureActivityDetailDto>;

  public pastActivityId?: string;
  public futureActivityId?: string;

  public activityPermissions: AssetPermissionModel = NO_PERMISSION;

  private calcRefresh: Observable<void> = this.refreshService.updates.pipe(
    filter((impactedIds) =>
      anyIdsInCommon(
        impactedIds.activityIds,
        (this.futureActivities ?? []).map((x) => x.id!)
      )
    ),
    map(() => undefined),
    startWith(undefined),
    takeUntil(this.destroy)
  );

  public constructor(
    private permissionService: PermissionService,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private stateService: StateService,
    private activitySevice: ActivityDataService,
    private futureActivitySevice: FutureActivityDataService,
    private activityTypeSevice: ActivityTypeDataService,
    private refreshService: RefreshService,
    private alertService: AlertService,
    private slideOutService: SlideOutService,
    private activityHistoryDeleteService: ActivityHistoryDeleteService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.route.params
      .pipe(
        take(1),
        tap((params) => this.setInputData(params))
      )
      .pipe(
        switchMap(() =>
          this.permissionService.getAssetPermissionModel(AssetFeature.Activity, this.inputData.equipmentKey!)
        ),
        tap((x) => {
          this.activityPermissions = x;
        }),
        switchMap(() => combineLatest([this.refreshData.pipe(startWith(null)), this.calcRefresh])),
        switchMap(() =>
          forkJoin([
            this.activitySevice.getActivities(this.inputData.equipmentKey),
            this.futureActivitySevice.getFutureActivities(this.inputData.equipmentKey),
            this.activityTypeSevice.getActivityTypesForAsset(this.inputData.equipmentKey)
          ])
        ),
        takeUntil(this.destroy)
      )
      .subscribe(([activities, futureActivities, activityTypes]) => {
        this.activityTypes = activityTypes;
        this.addButtonOptions = this.activityTypes!.map((x) => x.name);

        this.pastActivities = activities;
        this.futureActivities = futureActivities;

        if (this.stateService.staticState.flow.activityKey) {
          const activityTypesName = this.futureActivities!.find(
            (x) => x.id == this.stateService.staticState.flow.activityKey
          )!.activityType;

          const activityType = this.activityTypes!.find((x) => x.name == activityTypesName)!;

          this.editFutureActivity(this.stateService.staticState.flow.activityKey, activityType);
          this.stateService.setFlowActivity(undefined);
        } else if (this.stateService.staticState.flow.activityHistoryKey) {
          const activityTypesName = this.pastActivities!.find(
            (x) => x.id == this.stateService.staticState.flow.activityHistoryKey
          )!.activityType;

          this.activeTabId = 2;
          this.showHistoricalActivitySlideout(this.stateService.staticState.flow.activityHistoryKey, activityTypesName);
          this.stateService.setFlowActivityHistory(undefined);
        } else {
          this.alertService.updateIdsInEdit({ activityIds: futureActivities.map((x) => x.id ?? '') });
        }

        const selectedId = this.stateService.getSelectedGridId(this.getGridContext());

        if (selectedId !== undefined) {
          this.futureActivitiesGrid !== undefined
            ? this.futureActivitiesGrid?.selectRow(selectedId)
            : this.activitiesHistoryGrid?.selectRow(selectedId);
        }
      });

    this.stateService.state
      .pipe(
        map(() => this.stateService.getSelectedGridId(this.getGridContext())),
        filter((x) => x !== undefined),
        distinctUntilChanged(),
        takeUntil(this.destroy)
      )
      .subscribe((selectedId) => {
        const exists =
          this.futureActivitiesGrid !== undefined
            ? this.futureActivities?.find((x: FutureActivityDetailDto) => x.id === selectedId)
            : this.pastActivities?.find((x: ActivityDetailDto) => x.id === selectedId);

        if (exists === undefined) {
          this.refreshData.next();
        }

        this.futureActivitiesGrid !== undefined
          ? this.futureActivitiesGrid?.selectRow(selectedId!)
          : this.activitiesHistoryGrid?.selectRow(selectedId!);
      });
  }

  private getGridContext(): string {
    return this.activeTabId === 1
      ? this.futureActivitiesGridOptions.context
      : this.activitiesHistoryGridOptions.context;
  }

  private onSelectionChanged(): void {
    const selectedRow =
      this.futureActivitiesGrid !== undefined
        ? this.futureActivitiesGrid!.api.getSelectedRows()[0]
        : this.activitiesHistoryGrid!.api.getSelectedRows()[0];

    if (selectedRow !== undefined) {
      this.stateService.setSelectedGridId(this.getGridContext(), selectedRow.id);
    }
  }

  private setInputData(params: Params): void {
    this.inputData = {
      equipmentKey: params.equipmentKey,
      unitKey: params.unitKey,
      unitOfMeasure: params.unitOfMeasure
    };
  }

  public addFutureActivity(activityTypeName: string): void {
    const activityType = this.activityTypes!.find((x) => x.name == activityTypeName)!;

    const optionsData = this.futureActivitiesGrid!.convertNodesToSlideoutOptions<
      ActivityDetailDto,
      ActivitySlideoutOption
    >((data) => {
      return {
        name: data.activityType!,
        assetId: this.inputData.equipmentKey!,
        template: data.template!
      };
    });

    this.showFutureActivitySlideout({
      assetId: this.inputData.equipmentKey,
      initialActivityId: NEW_ITEM_KEY,
      activityType: activityType,
      optionsData: optionsData,
      breadcrumbs: this.breadcrumbs,
      gridContext: this.futureActivitiesGrid?.gridOptions.context
    });
  }

  private onViewFutureActivitySelect(params: ICellRendererParams): void {
    const activityType = this.activityTypes!.find((x) => x.name == params.data.activityType)!;

    this.editFutureActivity(params.data.id, activityType);
  }

  private editFutureActivity(id: string, activityType: ActivityTypeDto): void {
    const optionsData = this.futureActivitiesGrid!.convertNodesToSlideoutOptions<
      FutureActivityDetailDto,
      ActivitySlideoutOption
    >((data) => {
      return {
        name: data.activityType!,
        assetId: this.inputData.equipmentKey!,
        template: data.templateType!
      };
    });

    this.showFutureActivitySlideout({
      assetId: this.inputData.equipmentKey,
      initialActivityId: id,
      activityType: activityType,
      optionsData: optionsData,
      breadcrumbs: this.breadcrumbs,
      gridContext: this.futureActivitiesGrid?.gridOptions.context
    });
  }

  private showFutureActivitySlideout(data: FutureActivitySlideoutInput): void {
    this.slideOutService
      .open<boolean, FutureActivityDetailsComponent>(FutureActivityDetailsComponent, {
        data: data
      })
      .closed.pipe(take(1))
      .subscribe((x) => {
        if (typeof x == 'string') {
          this.newSurveyWorkflow();
        } else {
          this.refreshData.next();
        }
      });
  }

  private deleteFutureActivity(params: ICellRendererParams): void {
    const deleteDialogData = {
      title: `Delete "${params.data.activityType}"`,
      message: 'Are you sure?',
      buttons: DialogButtons.YesCancel,
      yesButtonText: 'Delete'
    } as DialogData;

    this.dialogService
      .display(deleteDialogData)
      .pipe(
        filter((x) => x === DialogResult.yes),
        concatMap(() => {
          return this.futureActivitySevice.deleteFutureActivity(params.data.id);
        }),
        tap((success) => {
          this.notificationService.showSaveResult(success, 'Delete');
          this.refreshData.next();
        })
      )
      .subscribe();
  }

  private newSurveyWorkflow(): void {
    this.updateTabId.emit(4); // CMLs tab
  }

  public addHistoricalActivity(activityTypeName: string): void {
    this.showHistoricalActivitySlideout(NEW_ITEM_KEY, activityTypeName);
  }

  private getHistoricalActivityDataFromGrid(): Record<string, HistoricalActivitySlideoutIdentifier> {
    const activityData: Record<string, ActivitySlideoutOption> = {};
    this.activitiesHistoryGrid?.api.forEachNodeAfterFilterAndSort((node) => {
      activityData[node.data.id!] = {
        name: node.data.name!,
        assetId: this.inputData.equipmentKey!,
        template: node.data.template!
      };
    });
    return activityData;
  }

  private editHistoricalActivity(params: ICellRendererParams<ActivityDetailDto>): void {
    const activity = params.data!;
    this.showHistoricalActivitySlideout(activity.id!, activity.activityType);
  }

  private showHistoricalActivitySlideout(id: string, activityTypeName: string): void {
    const activityType = this.activityTypes!.find((x) => x.name == activityTypeName)!;

    const data: ActivitySlideoutInput = {
      assetId: this.inputData.equipmentKey,
      initialActivityId: id,
      activityTemplate: activityType.template,
      initialActivityTypeId: activityType.id,
      gridContext: this.activitiesHistoryGridOptions.context,
      optionsData: this.getHistoricalActivityDataFromGrid(),
      breadcrumbs: this.breadcrumbs
    };

    this.slideOutService
      .open<boolean, ActivityDetailsSlideoutComponent>(ActivityDetailsSlideoutComponent, {
        data: data
      })
      .closed.pipe(take(1))
      .subscribe((x) => {
        if (typeof x == 'string') {
          this.newSurveyWorkflow();
        } else {
          this.refreshData.next();
        }
      });
  }

  private delete(params: ICellRendererParams): void {
    this.activityHistoryDeleteService.deleteActivity(params.data!).subscribe(() => {
      this.refreshData.next();
    });
  }

  public downloadExcelFileFuture(): void {
    this.futureActivitiesGridOptions.downloadExcelFile!();
  }

  public isAddButtonItemDisabled(type: string): boolean {
    const futureActivitiesInspections = this.futureActivities?.filter(
      (x) => x.templateType === ActivityTemplates.Inspection
    );

    if (futureActivitiesInspections === undefined) {
      return false;
    }

    const futureActivityCount = futureActivitiesInspections?.filter((x) => x.activityType === type);

    if (futureActivityCount === undefined) {
      return false;
    }

    return futureActivityCount.length >= 1;
  }
}
