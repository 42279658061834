import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastDisplayProperties } from 'src/app/shared/toast-module/models/toast';
import { ErrorToast, InfoToast, SuccessToast } from 'src/app/shared/toast-module/models/toast-type';

import { ToastService } from '../../shared/toast-module/toast.service';
import { ActionTypes } from '../models/action-types';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public constructor(private snackBar: MatSnackBar, private toastService: ToastService) {}

  public showInfo(message: string): void {
    this.showToast(new InfoToast(), message);
  }

  public showError(errorMessage: string): void {
    this.showToast(new ErrorToast(), errorMessage);
  }

  public showActionStarting(actionType: ActionTypes, name: string): void {
    const message = `${this.getActionStarting(actionType)} ${name}...`;
    this.showInfo(message);
  }

  public showActionResult(success: boolean, actionType: ActionTypes, name: string): void {
    const message = `${this.getActionResult(success, actionType)} ${name}${success ? '' : ' Failed'}`;
    const toastType = success ? new SuccessToast() : new ErrorToast();
    this.showToast(toastType, message);
  }

  private showToast(displayProperties: ToastDisplayProperties, message: string): void {
    this.toastService.show({ message: message, displayProperties: displayProperties });
  }

  private getActionResult(success: boolean, actionType: ActionTypes): string {
    switch (actionType) {
      case ActionTypes.Add:
        return success ? 'Added' : 'Add';
      case ActionTypes.Update:
        return success ? 'Saved' : 'Save';
      case ActionTypes.Rename:
        return success ? 'Renamed' : 'Rename';
      case ActionTypes.Copy:
        return success ? 'Copied' : 'Copy';
      case ActionTypes.Delete:
        return success ? 'Deleted' : 'Delete';
      case ActionTypes.Move:
        return success ? 'Moved' : 'Move';
      case ActionTypes.Upload:
        return success ? 'Uploaded' : 'Upload';
      case ActionTypes.Send:
        return success ? 'Sent' : 'Send';
      case ActionTypes.Load:
        return success ? 'Loaded' : 'Load';
      case ActionTypes.Download:
        return success ? 'Downloaded' : 'Download';
      case ActionTypes.Import:
        return success ? 'Imported' : 'Import';
      case ActionTypes.Share:
        return success ? 'Shared' : 'Share';
    }
  }

  private getActionStarting(actionType: ActionTypes): string {
    switch (actionType) {
      case ActionTypes.Add:
        return 'Adding';
      case ActionTypes.Update:
        return 'Updating';
      case ActionTypes.Rename:
        return 'Renaming';
      case ActionTypes.Copy:
        return 'Copying';
      case ActionTypes.Delete:
        return 'Deleting';
      case ActionTypes.Move:
        return 'Moving';
      case ActionTypes.Upload:
        return 'Uploading';
      case ActionTypes.Send:
        return 'Sending';
      case ActionTypes.Load:
        return 'Loading';
      case ActionTypes.Download:
        return 'Downloading';
      case ActionTypes.Import:
        return 'Importing';
      case ActionTypes.Share:
        return 'Sharing';
    }
  }
}
