import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { dateOnly, DateOnlyUtility, E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { ICellRendererParams } from 'ag-grid-community';
import {
  combineLatest,
  concatMap,
  filter,
  forkJoin,
  map,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { FutureActivityDto } from 'src/app/activity-details-module/models/future-activity-dto';
import { ActivityTypeDataService } from 'src/app/activity-type-data.service';
import { AlertService } from 'src/app/alert.service';
import { FindingSlideoutComponent } from 'src/app/asset-module/finding-slideout/finding-slideout.component';
import { AcceptFutureActivityDto } from 'src/app/asset-module/models/accept-future-activity-dto';
import { BaseSlideoutOption } from 'src/app/asset-module/models/base-slideout-option';
import { ActivityPhase } from 'src/app/asset-module/models/enums/activity-effectiveness';
import { ActivityStatus } from 'src/app/asset-module/models/enums/activity-status';
import { DueDateChoice } from 'src/app/asset-module/models/enums/due-date-choice';
import { FindingStatus } from 'src/app/asset-module/models/enums/finding-status';
import { InspectionMethodTypes } from 'src/app/asset-module/models/enums/inspection-method-types';
import { InspectorStatus } from 'src/app/asset-module/models/enums/inspector-status';
import { FindingDto } from 'src/app/asset-module/models/finding-dto';
import { FindingSlideoutInput } from 'src/app/asset-module/models/finding-slideout-input';
import { ActivityDataService } from 'src/app/asset-module/services/activity-data.service';
import { FutureActivityDataService } from 'src/app/asset-module/services/activity-future-data.service';
import { FindingDataService } from 'src/app/asset-module/services/finding-data.service';
import { AttachmentTableType } from 'src/app/attachments-module/models/attachment-table-type';
import { CompDataService } from 'src/app/comp-data.service';
import { EquipmentDataService } from 'src/app/equipment-data.service';
import { GridCellLinkParams } from 'src/app/grid-module/cell-renders/grid-cell-link/grid-cell-link-params';
import { GridCellLinkComponent } from 'src/app/grid-module/cell-renders/grid-cell-link/grid-cell-link.component';
import { GridCellTooltipComponent } from 'src/app/grid-module/cell-renders/grid-cell-tooltip/grid-cell-tooltip.component';
import { buildEnumColDef } from 'src/app/grid-module/column-builders/build-enum';
import { E2gAgGridComponent, GridBaseOptions } from 'src/app/grid-module/e2g-ag-grid/e2g-ag-grid.component';
import { InspectorService } from 'src/app/inspector.service';
import { ActivityTypeDto } from 'src/app/models/activity-type-dto';
import { ActivityTemplates } from 'src/app/models/enums/activity-templates';
import { AssetTypes } from 'src/app/models/enums/asset-types';
import { EquipmentDto } from 'src/app/models/equipment-dto';
import { InspectorDto } from 'src/app/models/inspector-dto';
import { RefreshService } from 'src/app/notifications-module/refresh.service';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';
import { DialogService } from 'src/app/shared-module/dialog.service';
import { DialogButtons } from 'src/app/shared-module/models/dialog-buttons';
import { DialogData } from 'src/app/shared-module/models/dialog-data';
import { DialogResult } from 'src/app/shared-module/models/dialog-result';
import { NEW_ITEM_KEY } from 'src/app/shared-module/models/new-item-key';
import { AssetFeature } from 'src/app/shared-module/permission/permission-models';
import { PermissionService } from 'src/app/shared-module/permission/permission.service';
import { NotificationService } from 'src/app/shared-module/services/notification.service';
import { SingleDataHandler } from 'src/app/shared-module/single-data-handler';
import { SlideOutContainerAction } from 'src/app/slide-out-module/models/constants/slide-out-container-action';
import { SlideOutFooterSection } from 'src/app/slide-out-module/models/slide-out-footer-item';
import { SlideOutContainerService } from 'src/app/slide-out-module/slide-out-container.service';
import { SLIDE_OUT_DATA } from 'src/app/slide-out-module/slide-out-data-injection-token';
import { SlideOutRef } from 'src/app/slide-out-module/slide-out-ref';
import { SlideOutService } from 'src/app/slide-out-module/slide-out.service';
import { StateService } from 'src/app/state.service';
import { SurveyService } from 'src/app/survey.service';

import { FutureActivitySlideoutInput } from '../models/future-activity-slideout-input';

enum FutureActivityTabs {
  Plan = 1,
  Execute = 2,
  Review = 3,
  Findings = 4
}

@Component({
  selector: 'app-future-activity-details',
  templateUrl: './future-activity-details.component.html',
  styleUrls: ['./future-activity-details.component.css']
})
export class FutureActivityDetailsComponent extends OnDestroyBaseComponent implements OnInit {
  @ViewChild('extraFooterButtons', { static: true }) public buttonTmpl!: TemplateRef<any>;
  @ViewChild('findingsGrid', { static: false }) public findingsGrid?: E2gAgGridComponent;

  private calcRefresh: Observable<void> = this.refreshService.updates.pipe(
    filter(
      (impactedIds) => !this.dataHandler.dirty && impactedIds.activityIds!.includes(this.dataHandler.data.id ?? '')
    ),
    map(() => undefined),
    startWith(undefined),
    takeUntil(this.destroy)
  );

  private equipment: EquipmentDto | undefined;

  public futureActivityTabs = FutureActivityTabs;
  public attachmentKey?: string;

  public loading: boolean = true;
  public saving: boolean = false;
  public tableTypePlanning = AttachmentTableType.ActivityFuturePlanning;
  public tableTypeInProgress = AttachmentTableType.ActivityFutureInProgress;
  public activeTabId = FutureActivityTabs.Plan;

  public dataHandler: SingleDataHandler<FutureActivityDto>;
  public componentsForAssetList: Array<E2gSelectOption> | undefined = [] as Array<E2gSelectOption>;
  public completionDateErrors: Array<string> = [];
  public completedByErrors: Array<string> = [];
  public inspectionMethodErrors: Array<string> = [];
  public existingSurveyDates: Array<dateOnly> = [];

  public dueDateErrors: Array<string> = [];
  public scheduledDateErrors: Array<string> = [];
  public dateIsTouched = false;
  public statusSelectOptions: Array<E2gSelectOption> = [
    { label: 'Not Planned', value: ActivityStatus.None },
    { label: 'Planned', value: ActivityStatus.Planned },
    { label: 'In Progress', value: ActivityStatus.InProgress },
    { label: 'Complete', value: ActivityStatus.Completed }
  ];

  public primaryInspectionMethodOptions: Array<E2gSelectOption> = [
    {
      value: InspectionMethodTypes.UT,
      label: InspectionMethodTypes[InspectionMethodTypes.UT]
    },
    {
      value: InspectionMethodTypes.RT,
      label: InspectionMethodTypes[InspectionMethodTypes.RT]
    }
  ];

  public loadingInspectorInfo = true;
  public inspectors: Array<InspectorDto> = [];
  public inspectorOptions: Array<E2gSelectOption> = [];
  public assignedInspectorIds: Array<string> = [];
  public completedByInspectorIds: Array<string> = [];

  public isAdding: boolean;
  public previewMode!: boolean;
  public readonly = true;
  private refreshActivityData = new Subject<void>();
  private refreshData = new Subject<void>();

  public gridReady = new Subject<void>();
  public findingsData: Array<FindingDto> = [];
  public findingsGridOptions: GridBaseOptions = {
    exportFileName: 'Activity Findings',
    context: 'findings-activity',
    suppressCellFocus: true,
    rowSelection: 'single',
    defaultColDef: {
      resizable: true,
      sortable: true,
      tooltipComponent: GridCellTooltipComponent,
      filter: 'agSetColumnFilter'
    },
    columnDefs: [
      {
        headerName: 'Name',
        headerTooltip: 'Name',
        field: 'name',
        tooltipField: 'name',
        comparator: (a, b): number => a.localeCompare(b, 'en', { numeric: true, sensitivity: 'base' }),
        minWidth: 250,
        width: 250,
        cellRenderer: GridCellLinkComponent,
        cellRendererParams: {
          isLink: (): boolean => !this.isForcedReadonly(),
          linkCommand: (params: ICellRendererParams): void => this.addOrEditFinding(params)
        } as GridCellLinkParams
      },
      {
        headerName: 'Description',
        headerTooltip: 'Description',
        field: 'description',
        tooltipField: 'description',
        minWidth: 250,
        width: 250
      },
      {
        headerName: 'Component',
        headerTooltip: 'Component',
        field: 'componentName',
        tooltipField: 'componentName',
        minWidth: 200,
        width: 200
      },
      {
        headerName: 'Maintenance Reference',
        headerTooltip: 'Maintenance Reference',
        field: 'maintenanceReference',
        tooltipField: 'maintenanceReference',
        minWidth: 250,
        width: 250
      },
      {
        ...buildEnumColDef('Status', 'status', FindingStatus),
        width: 125
      }
    ]
  };
  public activityPhase = [ActivityPhase.Planning, ActivityPhase.Execution];

  public constructor(
    private permissionService: PermissionService,
    private dataService: FutureActivityDataService,
    private activityService: ActivityDataService,
    private inspectorService: InspectorService,
    private activityTypeService: ActivityTypeDataService,
    private findingService: FindingDataService,
    private dialogService: DialogService,
    private surveyService: SurveyService,
    private slideOutRef: SlideOutRef,
    private slideOutContainerService: SlideOutContainerService,
    private slideOutService: SlideOutService,
    private notificationService: NotificationService,
    private stateService: StateService,
    private refreshService: RefreshService,
    private alertService: AlertService,
    private componentService: CompDataService,
    private equipService: EquipmentDataService,
    @Inject(SLIDE_OUT_DATA) private slideOutData: FutureActivitySlideoutInput
  ) {
    super();

    this.isAdding = this.slideOutData.initialActivityId === NEW_ITEM_KEY;

    this.configureSlideOut();

    this.dataHandler = new SingleDataHandler<FutureActivityDto>(
      this.destroy,
      (dirty) => {
        this.slideOutContainerService.setDirty(dirty);
      },
      undefined,
      () => {
        this.slideOutContainerService.setBodyValid(this.isValid());
      }
    );

    this.slideOutContainerService.setBodyValid(true);
  }

  public ngOnInit(): void {
    this.slideOutContainerService.setFooterItems([
      { position: SlideOutFooterSection.Right, template: this.buttonTmpl }
    ]);

    this.permissionService
      .getAssetPermissionModel(AssetFeature.Activity, this.slideOutData.assetId)
      .pipe(
        tap((x) => {
          this.readonly = !x.edit || this.isForcedReadonly();
        }),
        switchMap(() => this.refreshActivityData.pipe(startWith(undefined))),
        switchMap(() =>
          forkJoin([
            this.equipService.getSingleEquipment(this.slideOutData.assetId!),
            this.componentService.getComponents(this.slideOutData.assetId!)
          ])
        ),
        takeUntil(this.destroy)
      )
      .subscribe(([equipment, components]) => {
        this.equipment = equipment;

        this.componentsForAssetList = components.map((x) => ({
          value: x.id,
          label: `${x.name} (${x.type}, size: ${(x.size || '').replace(/_/gi, ' ')}, nom: ${x.nominalThickness})`
        })) as Array<E2gSelectOption>;

        if (this.slideOutData.initialActivityId === NEW_ITEM_KEY) {
          this.slideOutData.optionsData[NEW_ITEM_KEY] = {
            name: this.slideOutData.activityType!.name!,
            assetId: this.slideOutData.assetId!,
            template: this.slideOutData.activityType!.template
          };
        }

        this.slideOutContainerService.setOptions(this.buildDropdownOptions(this.slideOutData.optionsData!));
        this.slideOutContainerService.setOptionId(this.slideOutData.initialActivityId);
        this.slideOutContainerService.setBreadcrumbs(this.slideOutData.breadcrumbs!);
      });

    forkJoin([this.surveyService.getSurveys(this.slideOutData.assetId), this.inspectorService.getInspectors()])
      .pipe(
        tap(([surveys, insp]) => {
          this.existingSurveyDates = surveys.map((x) => x.date!);
          this.inspectors = insp.filter((x) => x.status == InspectorStatus.Active);
          this.inspectorOptions = this.inspectors.map((x) => ({ label: x.name!, value: x.id! } as E2gSelectOption));
          this.loadingInspectorInfo = false;
        }),
        concatMap(() =>
          combineLatest([
            this.slideOutContainerService.optionId,
            this.refreshData.pipe(startWith(undefined)),
            this.calcRefresh
          ]).pipe(
            tap(([id]) => {
              if (!this.isAdding) {
                this.stateService.setSelectedGridId(this.slideOutData.gridContext!, id);
              }

              this.dataHandler.clear();
              this.loading = true;
            }),
            switchMap(([id]) =>
              forkJoin([this.getActivity(id), this.isAdding ? of([]) : this.findingService.getActivityFindings(id)])
            ),
            takeUntil(this.destroy)
          )
        ),
        takeUntil(this.destroy)
      )
      .subscribe(([activity, findings]) => {
        this.initializeData(activity);
        this.findingsData = findings;
        this.alertService.updateIdsInEdit({ activityIds: [activity.id ?? ''] });

        if (this.isForcedReadonly()) {
          this.slideOutContainerService.setStaticHeading(
            this.slideOutData.optionsData![this.slideOutData.initialActivityId].name
          );

          this.slideOutContainerService.setBackButtonVisible(true);
        }
      });

    this.gridReady.subscribe(() => {
      if (this.slideOutData.findingsId) {
        this.findingsGrid!.selectRow(this.slideOutData.findingsId);
      }
    });

    if (this.isForcedReadonly()) {
      this.slideOutContainerService.setHeaderVisible(false);
    }
  }

  private configureSlideOut(): void {
    this.slideOutContainerService.setAllowRename(false);

    this.slideOutContainerService.action
      .pipe(
        filter((action) => action === SlideOutContainerAction.Revert),
        takeUntil(this.destroy)
      )
      .subscribe(() => {
        const statusChanged = this.dataHandler.isValueChanged('status');
        this.dataHandler.revertChanges();
        if (statusChanged) {
          this.onChangeStatus();
        }
      });

    this.slideOutContainerService.action
      .pipe(
        filter((action) => action === SlideOutContainerAction.Save || action === SlideOutContainerAction.SaveAndClose),
        switchMap((action) =>
          this.saveData().pipe(
            map((result) => {
              this.slideOutData.initialTabId = this.activeTabId;
              if (typeof result === 'string') {
                this.slideOutData.initialActivityId = result;
                this.notificationService.showSaveResult(true);
                this.isAdding = false;
                this.stateService.setSelectedGridId(this.slideOutData.gridContext!, result);

                this.addNewItemToHeaderDropdown(result);

                this.refreshActivityData.next();
              } else {
                this.notificationService.showSaveResult(result);
                this.refreshData.next();
              }

              return action;
            })
          )
        ),
        filter((action) => action === SlideOutContainerAction.SaveAndClose),
        tap(() => this.closeSlideout()),
        takeUntil(this.destroy)
      )
      .subscribe();

    this.slideOutContainerService.action
      .pipe(
        filter((action) => action === SlideOutContainerAction.Cancel),
        takeUntil(this.destroy)
      )
      .subscribe(() => {
        this.closeSlideout();
      });
  }

  public previewModeChange(value: boolean): void {
    this.previewMode = value;
  }

  public isForcedReadonly(): boolean {
    return this.slideOutData.findingSlideoutInput !== undefined;
  }

  private saveData(): Observable<string | boolean> {
    if (this.isAdding) {
      return this.dataService.addFutureActivity(this.getFormData());
    }
    return this.dataService.editFutureActivity(this.getFormData());
  }

  public getFormData(): any {
    return this.dataHandler.getRawData();
  }

  private getActivity(id: string): Observable<FutureActivityDto> {
    if (this.isAdding) {
      return this.activityService
        .getInspectionActivation(this.slideOutData.assetId, this.slideOutData.activityType!.id!, true)
        .pipe(
          map(
            (grades) =>
              ({
                governingDueDate: DueDateChoice.Calculated,
                status: ActivityStatus.None,
                inspectionGrades: grades,
                inspectors: [] as Array<InspectorDto>,
                inspectorsCompletedBy: [] as Array<InspectorDto>,
                assetId: this.slideOutData.assetId,
                activityTypeId: this.slideOutData.activityType!.id
              } as FutureActivityDto)
          )
        );
    } else {
      this.attachmentKey = id;
      return this.dataService.getFutureActivity(id);
    }
  }

  private closeSlideout(): void {
    if (this.slideOutData.findingSlideoutInput) {
      this.slideOutService.open<boolean, FindingSlideoutComponent>(FindingSlideoutComponent, {
        data: this.slideOutData.findingSlideoutInput
      });
    } else {
      this.slideOutRef.close();
    }
  }

  private initializeData(activity: FutureActivityDto): void {
    this.dataHandler.setInitialData(activity);
    this.slideOutContainerService.setDirty(this.dataHandler.dirty);

    if (this.slideOutData.currentEditData !== undefined) {
      this.dataHandler.updateData(this.slideOutData.currentEditData);
      this.slideOutData.currentEditData = undefined;
    }

    if (this.slideOutData.initialTabId) {
      this.activeTabId = this.slideOutData.initialTabId;
      this.slideOutData.initialTabId = undefined;
    } else {
      this.setDefaultTabForStatus();
    }

    this.assignedInspectorIds = activity.inspectors!.map((x) => x.id!);
    this.completedByInspectorIds = activity.inspectorsCompletedBy!.map((x) => x.id!);

    this.setupValidation();

    this.loading = false;
  }

  private setDefaultTabForStatus(): void {
    switch (this.dataHandler.data.status) {
      case ActivityStatus.Planned:
      case ActivityStatus.InProgress:
        this.activeTabId = FutureActivityTabs.Execute;
        break;
      case ActivityStatus.Completed:
        this.activeTabId = this.isValid()
          ? (this.activeTabId = FutureActivityTabs.Review)
          : (this.activeTabId = FutureActivityTabs.Execute);
        break;
      default:
        this.activeTabId = FutureActivityTabs.Plan;
    }
  }

  public isValid(): boolean {
    return (
      this.dueDateErrors.length === 0 &&
      this.completionDateErrors.length === 0 &&
      this.completedByErrors.length === 0 &&
      this.inspectionMethodErrors.length === 0
    );
  }

  public accept(): void {
    this.saving = true;
    this.slideOutContainerService.setDirty(false);
    (this.slideOutData.activityType?.id !== this.dataHandler.data.activityTypeId
      ? this.activityTypeService.getActivityType(this.dataHandler.data.activityTypeId!)
      : of(this.slideOutData.activityType)
    )
      .pipe(
        map((activityType) => this.getAcceptanceDto(activityType!)),
        switchMap((data) =>
          this.showAcceptDialog(data) ? this.showResetFutureActivityDialog(data) : this.acceptFutureActivity(data)
        )
      )
      .subscribe(() => (this.saving = false));
  }

  private showAcceptDialog(dto: AcceptFutureActivityDto): boolean {
    return !this.isSurvey() && !dto.resetFutureActivity;
  }

  private getAcceptanceDto(activityType: ActivityTypeDto): AcceptFutureActivityDto {
    this.slideOutData.activityType = activityType;
    const data: AcceptFutureActivityDto = {
      acceptedDate: DateOnlyUtility.today(),
      resetFutureActivity:
        !!this.slideOutData.activityType.halfLife ||
        (this.slideOutData.activityType.maximumInterval && this.slideOutData.activityType.maximumInterval > 0) ||
        !!this.slideOutData.activityType.rbiThinning ||
        !!this.slideOutData.activityType.rbiCracking ||
        !!this.slideOutData.activityType.rbiExternal,
      ...this.getFormData()
    };

    return data;
  }

  private showResetFutureActivityDialog(dto: AcceptFutureActivityDto): Observable<string> {
    const dialogData = {
      message:
        'This activity type does not have a next due date configuration. Do you want to schedule the next activity?',
      buttons: DialogButtons.YesNo
    } as DialogData;

    return this.dialogService.display(dialogData).pipe(
      concatMap((x) => {
        dto.resetFutureActivity = x === DialogResult.yes;
        return this.dataService.acceptFutureActivity(dto);
      }),
      tap((id) => {
        const success = id !== '';
        this.notificationService.showSaveResult(success);
        if (dto.resetFutureActivity) {
          if (this.isAdding) {
            this.slideOutData.initialActivityId = id;
            this.isAdding = false;
            this.stateService.setSelectedGridId(this.slideOutData.gridContext!, id);
          }
          this.refreshData.next();
        } else {
          this.slideOutRef.close();
        }
      })
    );
  }

  private acceptFutureActivity(dto: AcceptFutureActivityDto): Observable<string> {
    return this.dataService.acceptFutureActivity(dto).pipe(
      tap((id) => {
        const success = id !== '';
        this.notificationService.showSaveResult(success);
        if (success && this.isSurvey()) {
          this.surveyService.newSurveyDate = dto.completionDate;
          this.slideOutRef.close(id);
        } else if (this.isAdding) {
          this.slideOutData.initialActivityId = id;
          this.isAdding = false;
          this.stateService.setSelectedGridId(this.slideOutData.gridContext!, id);
          this.refreshActivityData.next();
        } else {
          this.refreshActivityData.next();
        }
      })
    );
  }

  public effectivenessVisible(): boolean {
    return this.dataHandler.data.inspectionGrades !== undefined && this.dataHandler.data.inspectionGrades.length > 0;
  }

  public isComplete(): boolean {
    return this.dataHandler.data.status === ActivityStatus.Completed;
  }

  public hasStartedWork(): boolean {
    return this.dataHandler.data.status === ActivityStatus.InProgress || this.isComplete();
  }

  public hasStartedPlanning(): boolean {
    return this.dataHandler.data.status === ActivityStatus.Planned || this.hasStartedWork();
  }

  public validateSpecifiedDueDate(): Observable<string | undefined> {
    this.dueDateErrors = [];

    if (this.specifiedDueDateRequired()) {
      if (!DateOnlyUtility.isValid(this.dataHandler.data.specifiedDueDate)) {
        this.dueDateErrors.push('Valid date required');
      }
    }

    this.slideOutContainerService.setBodyValid(this.isValid());
    return of(this.dueDateErrors.length > 0 ? 'ERROR' : undefined);
  }

  public specifiedDueDateRequired(): boolean {
    return this.dataHandler.data.governingDueDate == DueDateChoice.Specified;
  }

  private validateCompletionDate(): Observable<string | undefined> {
    this.completionDateErrors = [];

    if (this.isComplete() && !DateOnlyUtility.isValid(this.dataHandler.data.completionDate)) {
      this.completionDateErrors.push('Valid date required');
    } else if (this.hasStartedPlanning() && DateOnlyUtility.isValid(this.dataHandler.data.completionDate)) {
      if (this.isSurvey()) {
        if (this.existingSurveyDates.find((x) => x! == this.dataHandler.data.completionDate!)) {
          this.completionDateErrors.push('Survey Already Exists for the Selected Completion Date');
        }
      }
    }

    this.slideOutContainerService.setBodyValid(this.isValid());

    return of(this.completionDateErrors.length > 0 ? 'ERROR' : undefined);
  }

  private validateCompletedBy(): Observable<string | undefined> {
    this.completedByErrors = [];

    if (this.isComplete()) {
      if (this.dataHandler.data.inspectorsCompletedBy?.length <= 0) {
        this.completedByErrors.push('Must Specify Inspector');
      }
    }

    this.slideOutContainerService.setBodyValid(this.isValid());
    return of(this.completedByErrors.length > 0 ? 'ERROR' : undefined);
  }

  private validateInspectionMethod(): Observable<string | undefined> {
    this.inspectionMethodErrors = [];

    if (this.isComplete() && this.isSurvey()) {
      if (this.dataHandler.data.primaryInspectionMethod === undefined) {
        this.inspectionMethodErrors.push('Must Specify method');
      }
    }

    this.slideOutContainerService.setBodyValid(this.isValid());
    return of(this.inspectionMethodErrors.length > 0 ? 'ERROR' : undefined);
  }

  public onChangeStatus(): void {
    this.validateCompletionDate();
    this.validateCompletedBy();
    this.validateInspectionMethod();
    if (!this.isTabVisible(this.activeTabId) || this.isComplete()) {
      this.setDefaultTabForStatus();
    }
  }

  public isTabVisible(tab: FutureActivityTabs): boolean {
    switch (tab) {
      case FutureActivityTabs.Execute:
        return this.hasStartedPlanning();
      case FutureActivityTabs.Review:
        return this.isComplete();
      case FutureActivityTabs.Findings:
        return !this.isAdding && !this.isForcedReadonly() && this.hasStartedWork();
    }
    return true;
  }

  private setupValidation(): void {
    this.dataHandler.setValidation({
      specifiedDueDate: () => this.validateSpecifiedDueDate(),
      governingDueDate: () => this.validateSpecifiedDueDate(),
      completionDate: () => this.validateCompletionDate(),
      inspectorsCompletedBy: () => this.validateCompletedBy(),
      primaryInspectionMethod: () => this.validateInspectionMethod()
    });
    this.validateSpecifiedDueDate();
    this.validateCompletionDate();
    this.validateCompletedBy();
    this.validateInspectionMethod();
  }

  public addOrEditFinding(params: ICellRendererParams | undefined): void {
    this.slideOutData.initialTabId = FutureActivityTabs.Findings;
    this.slideOutData.findingsId = params !== undefined ? params.data.id : NEW_ITEM_KEY;
    this.slideOutData.currentEditData = this.dataHandler.data;
    this.slideOutData.initialActivityId = this.dataHandler.data.id!;

    const optionsData = this.findingsGrid!.convertNodesToSlideoutOptions<FindingDto, BaseSlideoutOption>((data) => {
      return {
        name: data.name!,
        assetId: data.assetId!
      };
    });

    const slideoutData: FindingSlideoutInput = {
      id: params !== undefined ? params.data.id : NEW_ITEM_KEY,
      assetId: this.slideOutData.assetId,
      futureActivitySlideoutInput: this.slideOutData,
      optionsData: optionsData,
      breadcrumbs: this.slideOutData.breadcrumbs,
      gridContext: this.findingsGrid?.gridOptions.context
    };

    this.slideOutService.open<boolean, FindingSlideoutComponent>(FindingSlideoutComponent, {
      data: slideoutData
    });
  }

  public onAssignedInspectorChange(): void {
    this.dataHandler.data.inspectors = this.inspectors.filter((x) => this.assignedInspectorIds.includes(x.id!));
  }

  public onCompletedByInspectorChange(): void {
    this.dataHandler.data.inspectorsCompletedBy = this.inspectors.filter((x) =>
      this.completedByInspectorIds.includes(x.id!)
    );
  }

  public isSurvey(): boolean {
    return (
      (this.isAdding
        ? this.slideOutData.activityType!.template
        : this.slideOutData.optionsData![this.dataHandler.data.id!].template) === ActivityTemplates.Survey
    );
  }

  public isPrd(): boolean {
    return this.equipment?.type == AssetTypes.PressureReliefDevice;
  }

  private buildDropdownOptions(data: Record<string, BaseSlideoutOption>): Array<E2gSelectOption> {
    return Object.entries(data).map(([key, value]) => ({ value: key, label: value.name }));
  }

  private addNewItemToHeaderDropdown(id: string): void {
    delete this.slideOutData.optionsData[NEW_ITEM_KEY];

    this.slideOutData.optionsData[id] = {
      name: this.slideOutData.activityType!.name!,
      assetId: this.slideOutData.assetId!,
      template: this.slideOutData.activityType!.template
    };

    this.slideOutContainerService.setOptions(this.buildDropdownOptions(this.slideOutData.optionsData));
  }
}
