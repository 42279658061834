import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faRotateLeft } from '@fortawesome/pro-solid-svg-icons';
import { combineLatest, ReplaySubject, Subject, switchMap, takeUntil } from 'rxjs';
import { AssetDefaultsConfigurationDto } from 'src/app/asset-module/models/asset-defaults-configuration-dto';
import { AssetTypes } from 'src/app/models/enums/asset-types';
import { UnitOfMeasure } from 'src/app/models/enums/unit-of-measure';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';
import { UnitsOfMeasureEvaluator } from 'src/app/units-of-measure/units-of-measure-evaluator';

import { AssetGoverningInspectionDomainModel } from './asset-governing-inspection-domain-model';

@Component({
  selector: 'app-asset-governing-inspection-settings',
  templateUrl: './asset-governing-inspection-settings.component.html',
  styleUrls: ['./asset-governing-inspection-settings.component.css']
})
export class AssetGoverningInspectionSettingsComponent extends OnDestroyBaseComponent implements OnInit {
  @Input() public model!: AssetGoverningInspectionDomainModel<AssetDefaultsConfigurationDto>;
  @Input() public assetDefaultsConfiguration?: AssetDefaultsConfigurationDto;
  @Input() public readonly?: boolean = false;
  @Input() public hasProductSCRA: boolean = false;
  @Input() public set unitOfMeasure(value: UnitOfMeasure) {
    this.refreshUOM.next(value);
  }
  @Input() public assetType?: AssetTypes;
  @Output() public isValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  private refreshUOM: ReplaySubject<UnitOfMeasure> = new ReplaySubject();
  public refreshDueDateBasis: Subject<void> = new Subject();

  public resetIcon = faRotateLeft;

  public scraEnabled: boolean = false;

  public corrosionRateUom: string = '';
  public thicknessUom: string = '';

  public showMawpApproach: boolean = true;
  public dueDateBasisValid: boolean = false;

  public governingMinimumThickness: string = 'governingMinimumThickness';
  public governingCorrosionRate: string = 'governingCorrosionRate';
  public cmlDueDateBasis: string = 'cmlDueDateBasis';

  public constructor(private uomEvaluator: UnitsOfMeasureEvaluator) {
    super();
  }

  public ngOnInit(): void {
    this.refreshUOM
      .pipe(
        switchMap((uom) =>
          combineLatest([
            this.uomEvaluator.getUnitsWithUnitSystem('thickness', uom),
            this.uomEvaluator.getUnitsWithUnitSystem('estimatedCorrosionRate', uom)
          ])
        ),
        takeUntil(this.destroy)
      )
      .subscribe(([th, cr]) => {
        this.thicknessUom = th;
        this.corrosionRateUom = cr;
      });
  }

  public validate(): void {
    this.isValid.emit(
      (this.dueDateBasisValid ?? false) &&
        (this.model.useMawpApproach ||
          this.model.isRetirementThickness ||
          this.model.isStructuralRequiredThickness ||
          this.model.isCorrodedThickness ||
          this.model.isSpecifiedThickness) &&
        (this.model.isLongRateGoverning ||
          this.model.isShortRateGoverning ||
          this.model.isLinearRateGoverning ||
          this.model.isScraMeasuredLongRateGoverning ||
          this.model.isScraMeasuredShortRateGoverning ||
          this.model.isScraStatisticalLongRateGoverning ||
          this.model.isScraStatisticalShortRateGoverning ||
          this.model.isEstimatedCorrosionRate)
    );
  }

  public getWarningsEstimatedCorrosionRate(): Array<string> {
    const estimatedCorrosionRateWarnings = [] as Array<string>;

    if (this.model.estimatedCorrosionRate && Number(this.model.estimatedCorrosionRate) >= 0) {
      estimatedCorrosionRateWarnings.push(
        'This value is only used when the group estimated rate is not input or linked.'
      );
    }

    return estimatedCorrosionRateWarnings;
  }

  public getErrorsEstimatedCorrosionRate(): Array<string> {
    const estimatedCorrosionRateErrors = [] as Array<string>;

    if (Number(this.model.estimatedCorrosionRate) < 0) {
      estimatedCorrosionRateErrors.push('Estimated corrosion rate cannot be less than zero.');
    }

    return estimatedCorrosionRateErrors;
  }

  public areTMinCheckboxesDisabled(): boolean {
    return this.model.useMawpApproach ?? false;
  }

  public resetGoverningCorrosionRate(): void {
    if (this.assetDefaultsConfiguration) {
      this.model.isLongRateGoverning = this.assetDefaultsConfiguration.isLongRateGoverning;
      this.model.isShortRateGoverning = this.assetDefaultsConfiguration.isShortRateGoverning;
      this.model.isLinearRateGoverning = this.assetDefaultsConfiguration.isLinearRateGoverning;
      this.model.isScraMeasuredLongRateGoverning = this.assetDefaultsConfiguration.isScraMeasuredLongRateGoverning;
      this.model.isScraMeasuredShortRateGoverning = this.assetDefaultsConfiguration.isScraMeasuredShortRateGoverning;
      this.model.isScraStatisticalLongRateGoverning =
        this.assetDefaultsConfiguration.isScraStatisticalLongRateGoverning;
      this.model.isScraStatisticalShortRateGoverning =
        this.assetDefaultsConfiguration.isScraStatisticalShortRateGoverning;
      this.model.isEstimatedCorrosionRate = this.assetDefaultsConfiguration.isEstimatedCorrosionRate;
      this.model.silentClearValue(this.governingCorrosionRate);
      this.model.silentSetValue(this.governingCorrosionRate, 1, true);

      this.validate();
    }
  }

  public resetCmlDueDateBasis(): void {
    if (this.assetDefaultsConfiguration) {
      this.model.halfLife = this.assetDefaultsConfiguration.halfLife;
      this.model.maximumInterval = this.assetDefaultsConfiguration.maximumInterval;
      this.model.silentClearValue(this.cmlDueDateBasis);
      this.model.silentSetValue(this.cmlDueDateBasis, 1, true);

      this.refreshDueDateBasis.next();

      this.validate();
    }
  }

  public resetGoverningMinimumThickness(): void {
    if (this.assetDefaultsConfiguration) {
      this.model.useMawpApproach = this.assetDefaultsConfiguration.useMawpApproach;
      this.model.isRetirementThickness = this.assetDefaultsConfiguration.isRetirementThickness;
      this.model.isStructuralRequiredThickness = this.assetDefaultsConfiguration.isStructuralRequiredThickness;
      this.model.isCorrodedThickness = this.assetDefaultsConfiguration.isCorrodedThickness;
      this.model.isSpecifiedThickness = this.assetDefaultsConfiguration.isSpecifiedThickness;
      this.model.silentClearValue(this.governingMinimumThickness);
      this.model.silentSetValue(this.governingMinimumThickness, 1, true);

      this.validate();
    }
  }

  public onGoverningCorrosionRateChange(): void {
    (this.model as any)[this.governingCorrosionRate] = 0;
  }

  public onGoverningMinimumThicknessChange(): void {
    (this.model as any)[this.governingMinimumThickness] = 0;
  }

  public onCmlDueDateBasisChange(): void {
    (this.model as any)[this.cmlDueDateBasis] = 0;
  }

  public isAssetTypeOther(): boolean {
    return this.assetType ? this.assetType == AssetTypes.Other : false;
  }
}
