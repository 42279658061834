import { Component, EventEmitter, Output, TemplateRef } from '@angular/core';
import { combineLatest, filter, map, Observable } from 'rxjs';
import { NEW_ITEM_KEY } from 'src/app/shared-module/models/new-item-key';

import { SlideOutContainerAction } from '../models/constants/slide-out-container-action';
import { SlideOutFooterItem, SlideOutFooterSection } from '../models/slide-out-footer-item';
import { SlideOutContainerService } from '../slide-out-container.service';

@Component({
  selector: 'app-slide-out-footer',
  templateUrl: './slide-out-footer.component.html'
})
export class SlideOutFooterComponent {
  @Output() public triggerAction = new EventEmitter<SlideOutContainerAction>();

  public saving: boolean = false;
  public new: Observable<boolean> = this.slideOutService.optionId.pipe(map((x) => x === NEW_ITEM_KEY));
  public dirty: Observable<boolean> = this.slideOutService.dirty;
  public valid: Observable<boolean> = combineLatest([
    this.slideOutService.headerVisible,
    this.slideOutService.headerValid,
    this.slideOutService.bodyValid
  ]).pipe(map(([headerVisible, headerValid, body]) => (!headerVisible || headerValid) && body));

  public extraLeftItems: Array<TemplateRef<any>> = [];
  public extraCenterItems: Array<TemplateRef<any>> = [];
  public extraRightItems: Array<TemplateRef<any>> = [];
  public saveButtonText: string = 'Save';

  public constructor(private slideOutService: SlideOutContainerService) {
    this.slideOutService.dirty.pipe(filter((isDirty) => !isDirty)).subscribe(() => (this.saving = false));
    this.slideOutService.extraFooterItems.subscribe((x) => {
      this.extraLeftItems = this.getFooterItemsByPosition(x, SlideOutFooterSection.Left);
      this.extraCenterItems = this.getFooterItemsByPosition(x, SlideOutFooterSection.Center);
      this.extraRightItems = this.getFooterItemsByPosition(x, SlideOutFooterSection.Right);
    });

    this.slideOutService.saveButtonText.subscribe((saveBtnText: string) => {
      this.saveButtonText = saveBtnText;
    });
  }

  private getFooterItemsByPosition(
    items: Array<SlideOutFooterItem>,
    position: SlideOutFooterSection
  ): Array<TemplateRef<any>> {
    return items.filter((y) => y.position == position).map((y) => y.template);
  }

  public onCancel(): void {
    this.triggerAction.emit(SlideOutContainerAction.Cancel);
  }

  public onRevertChanges(): void {
    this.triggerAction.emit(SlideOutContainerAction.Revert);
  }

  public onSave(): void {
    this.saving = true;
    this.triggerAction.emit(SlideOutContainerAction.Save);
  }
}
